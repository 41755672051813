/* 
 * @author              Juan Acosta <juan.93.ctm@gmail.com>
 * @version             0.0.1
 * @copyright           Todos los derechos reservados para Cooperativa de Trabajo Código Libre Ltda.
*/


import React from 'react';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      file:null
    }
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.fileUpload = this.fileUpload.bind(this)
  }

  onFormSubmit(e){
    e.preventDefault(); // Stop form submit
    this.fileUpload(this.state.file);
  }

  onChange(e) {
    this.setState({file: e.target.files[0]});
  }

  fileUpload(file){
    console.log(file);
    var lines = [];
    var reader = new FileReader();
                
    reader.onload = function(elem){
        var data = elem.target.result;  
        lines = data.split('\r\n');
        console.log(lines);

        function downloadImpl(filename, text) {
          var element = document.createElement('a');
          element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
          element.setAttribute('download', filename);
      
          element.style.display = 'none';
          document.body.appendChild(element);
      
          element.click();
      
          document.body.removeChild(element);
        }

        let start = false;
        let startI = 0;
        let res = [];
        let filename = file.name.slice(0, file.name.lastIndexOf(".")) + "_1.txt";

        // para quitar los retornos de carro
        // lines = lines.map(l => l.slice(0,-1));

        // aca se arman las lineas que empiezan con el codigo 04
        lines.forEach((l,i) => {
          if(!start && l.slice(0,2) === "04"){
            start = true;
            startI = i;
          }

          if(start && (i-startI)%2 === 1){
            res[res.length - 1] = res[res.length - 1].concat(l);
          } else {
            res.push(l);
          }
        });

        console.log(res);

        let downloadText = res.reduce((r,l,i) => 
          r + (i === 0? "" : "\r\n") + l
        , "");

        downloadImpl(filename, downloadText);

    };
    reader.readAsText(file, "ASCII");
  }

  render() {
    return (
      <div className="aa-home">
        <h1 className="aa-home-label">Interfaz libro de sueldos digital</h1>
        <form className="aa-form" onSubmit={this.onFormSubmit}>
          <div className="aa-form-input">
            <label className="login-form-input-item">
              Ingrese el archivo:
            </label>
            <input type="file" onChange={this.onChange}/>
          </div>

          <div class="aa-form-separator">
            <div class="aa-form-separator-rectangle"/>
            <div class="aa-form-separator-triangle"/>
          </div>

          <div className="aa-form-input">
            <button type="submit">Descargar versión corregida</button>
          </div>
        </form>
      </div>
    )
  }
}

export default Login;
